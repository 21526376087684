<template>
  <table class="table table-bordered table-striped">
    <caption>
      Rincian Rekening
    </caption>
    <tbody>
      <tr>
        <td>Nomor Rekening</td>
        <th
          scope
          v-if="!onEdited"
          class="d-flex justify-content-between w-100 align-items-center"
        >
          <span>
            {{ accountNumber ?? "-" }}
          </span>
          <button
            v-if="enableEdited"
            type="button"
            class="btn btn-light"
            @click="enabledEdited"
          >
            <Icon name="Pencil" size="md" color="black" />
          </button>
        </th>
        <th scope v-else>
          <form @submit.prevent="verify" class="d-flex justify-content-between">
            <div class="input-group border rounded w-75">
              <input
                class="form-control border-0"
                @input="$emit('updateNumber', $event.target.value)"
                v-model="number_bank"
              />
              <button type="button" class="btn border-0 btn-white">
                <Icon name="Pencil" size="md" color="black" />
              </button>
            </div>
            <button
              type="submit"
              class="btn btn-transparent text-primary fw-bold"
            >
              Check
            </button>
          </form>
        </th>
      </tr>
      <tr v-if="!onEdited">
        <td>Nama Rekening</td>
        <th scope>
          {{ holderName ?? "-" }}
        </th>
      </tr>
      <tr v-else>
        <td>Nama Rekening</td>
        <th scope>-</th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import BaseInput from "@/components/input/BaseInput.vue";
import AccountService from "@/services/account";

export default {
  components: {
    BaseInput,
  },
  props: {
    isEdited: {
      type: Boolean,
      default: false,
    },
    enableEdited: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "Giro",
    },
    accountNumber: {
      type: String,
      default: "-",
    },
    holderName: {
      type: String,
      default: "-",
    },
  },
  data() {
    return {
      number_bank: "-",
      holder_bank: "-",
      onEdited: this.isEdited,
    };
  },
  methods: {
    enabledEdited() {
      this.onEdited = !this.onEdited;

      this.$emit("disabled-button", true);
    },
    async verify() {
      this.onEdited = true;

      try {
        const payload = {
          account_number: this.number_bank,
          account_type: this.type.toLowerCase(),
        };

        const response = await AccountService.inquiry(payload);

        const account = {
          number: this.number_bank,
          name: response.data.data.name.trim(),
        };

        this.$emit("account-updated", account);
      } catch (error) {
        this.$emit("disabled-button", false);

        alert("No. Rekening Tidak Ditemukan");
      }

      this.onEdited = false;
    },
  },
  mounted() {
    this.number_bank = this.accountNumber;
    this.holder_bank = this.holderName;
  },
};
</script>

<style scoped>
th,
td {
  width: 50%;
  padding: 8px 16px !important;
  height: 56px;
  vertical-align: middle;
}
</style>
