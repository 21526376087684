<template>
  <table class="table table-bordered table-striped">
    <caption>
      Rincian Nomor
    </caption>
    <tbody>
      <tr>
        <td>Nomor Akta Pendirian</td>
        <th scope>{{ deedNumber }}</th>
      </tr>
      <tr>
        <td>Nomor Akta Pengesahan</td>
        <th scope>{{ validationNumber }}</th>
      </tr>
      <tr>
        <td>Tempat Pendirian</td>
        <th scope>{{ city }}</th>
      </tr>
      <tr>
        <td>Tanggal Pendirian</td>
        <th scope>
          {{
            establishedDate !== "" ? this.fullDateFormat(establishedDate) : ""
          }}
        </th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: ["establishedDate", "city", "validationNumber", "deedNumber"],
  methods: {},
  mounted() {
    console.log("CEK PROPS DOC");
  },
};
</script>

<style scoped>
th,
td {
  width: 50%;
  padding: 8px 16px !important;
  height: 56px;
  vertical-align: middle;
}
</style>
