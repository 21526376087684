<template>
  <table class="table table-bordered table-striped">
    <caption>
      Rincian Modal
    </caption>
    <tbody>
      <tr>
        <td>Modal Usaha</td>
        <th scope>{{ rupiahFormat(capital) }}</th>
      </tr>
      <tr>
        <td>Harga Pokok Penjualan (Per Bulan)</td>
        <th scope>{{ rupiahFormat(salesCost) }}</th>
      </tr>
      <tr>
        <td>Biaya Operasional (Per Bulan)</td>
        <th scope>{{ rupiahFormat(operational) }}</th>
      </tr>
      <tr>
        <td>Pendapatan Tahunan</td>
        <th scope>{{ annualIncome }}</th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: ["annualIncome", "operational", "salesCost", "capital"],
};
</script>

<style scoped>
th,
td {
  width: 50%;
  padding: 8px 16px !important;
  height: 56px;
  vertical-align: middle;
}
</style>
